import React from "react";
import axios from "axios";
import { useEffect, useRef } from "react";

export default function FortisElements(props) {
    const { token, drawDownArrow, setLoaded, setMessage, setRetry, fortisClientToken } = props;

    let fortisErrorHideIframe = useRef();

    useEffect(() => {   
        const elements = new window.Commerce.elements(fortisClientToken);

        elements.create({
            container: "#fortisElementsRoot",
            theme: "default",
            environment: "production",
            showReceipt: true,
            showSubmitButton: true,
            fields: {
                billing: false,
            },
            appearance: {
                fontSize: "12px",
                marginSpacing: "0rem",
                borderRadius: "4px",
                rowMarginSpacing: "0rem",
                colorButtonBackground: "#8a8a8a",
                colorButtonActionBackground: "#3b82f6",
                colorButtonSelectedBackground: "#3b82f6",
                colorButtonText: '#ffffff',
                colorFieldBorder: '#e2e8f0'
            },
        });

        elements.off('ready')
        elements.on('ready', function (event) {
            setLoaded(true)
            setTimeout(() => {
                drawDownArrow()
            }, 1);
        })

        elements.off('done');
        elements.on('done', function (e) {
            if (e.data.reason_code_id === '1000' || e.data.reason_code_id === 1000) {
				axios.post('https://api.quikpays.io/transaction-data', { token: token }).then(
					// nothing usefull in successfull response right now, just catch error here
				).catch((error) => {
					if (error.response) {
                        // something in RW app broken
						console.log(error.response.data)
					}
				})
			} else {
                setMessage({ type: 'error', message: 'You payment method has been declined. Please try again with a different payment method.' });
				setRetry(true);
			}
        });

        elements.off('tokenExpired');
        elements.on('tokenExpired', function (event) {
            // fortisErrorHideIframe.current = true;
            setMessage({ type: 'error', message: 'This page has expired. Refresh the page to make your payment.' });
        });

        elements.off('error');
        elements.on('error', function (event) {
			if (event.data) {
	            setMessage({ type: 'error', message: 'Oops! Something went wrong. Please try again later.' })
			}
        });
    }, [token, drawDownArrow, fortisClientToken, setLoaded, setMessage, setRetry])

    return (
        <div id="fortisElementsRoot" className={fortisErrorHideIframe.current ? 'hidden' : ''}></div>
    )
}