import React from "react";

export default function Messages(props) {
    const {type, message, showRefreshButton} = props

    const refresh = () => {
        window.location.reload();
    }

    return (
        <div className="message-wrap">
            <div className={'message ' + type}>
                <p>{message}</p>
                {showRefreshButton && <button onClick={refresh}>Refresh</button>}
            </div>
        </div>
    )
}