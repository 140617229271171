import React from "react";

export default function Totals(props) {
    const {invoiceTotal} = props

    return(
        <ul className="styleless totals">
            <li className="total title">Invoice Total</li>
            <li className="total amount">${invoiceTotal}</li>
        </ul>
    )
}