import Messages from "./components/Messages"

export default function ErrorPage() {
    const message = {
        type: 'error',
        message: 'Oops! Something went wrong. Please try again later.'
    }

    return (
        <div className="main">
            <Messages {...message} />
        </div>
    )
}