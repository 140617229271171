import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./Main";
import ErrorPage from "./ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        errorElement: <ErrorPage />
    },
    {
        path: "/:token",
        element: <Main />,
        errorElement: <ErrorPage />
    }
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
