import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import FortisElements from "./components/FortisElements";
import Messages from "./components/Messages";
import Totals from "./components/Totals";
import rwLogo from "./powered-by-rw.png";
import downArrow from "./down-arrow.svg";
import {useParams} from "react-router-dom";

export default function Main() {
    const {token} = useParams();

    const [totalsData, setTotalsData] = useState();
    const [message, setMessage] = useState();
    const [loaded, setLoaded] = useState(false);
    const [retry, setRetry] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [tooBig, setTooBig] = useState(false);
    const [fortisClientToken, setFortisClientToken] = useState(null);
    const [seed, setSeed] = useState(1);
    
    let logo = useRef();
    let company = useRef();
    let contact = useRef();
    // let fortisErrorHideIframe = useRef(); // may need in prod to hide iframe when an error occurs.

    const drawDownArrow = useCallback(() => {
        const h = document.querySelector('.wrapper').offsetHeight
        const winH = window.innerHeight
        const offset = 125

        if (h - offset > winH) {
            setTooBig(true)
        } else {
            setTooBig(false)
        }

        window.onscroll = function (ev) {
            if ((window.innerHeight + Math.round(window.scrollY)) + offset >= document.body.offsetHeight) {
                setTooBig(false)
            } else {
                setTooBig(true)
            }
        };
    }, [])

    useEffect(() => {        
        setMessage(null)
        setRetry(false)
        // fortisErrorHideIframe.current = false;

        if (!token) {
            return
        }
        
        const fetchData = async () => {
            await axios.get('https://api.quikpays.io/client-token?token=' + token).then(data => {

                const respData = data.data || {}
                const invoiceTotal = respData.balance_amount || 0

                setTotalsData({
                    invoiceTotal: invoiceTotal
                })

                logo.current = respData.account_logo_url
                company.current = respData.account_business_name
                contact.current = respData.contact_name

                if (respData.transaction_token) {
                    setFortisClientToken(respData.transaction_token);
                }

                setValidToken(true)

            }).catch((error) => {
                error.response?.data?.message && setMessage({ type: 'error', message: 'Oops! Something went wrong. Please try again later.' });

                setLoaded(true)
            })
        }

        fetchData().catch(console.log)

        window.addEventListener('load', function () {
            drawDownArrow()
        });
        window.addEventListener('resize', function () {
            drawDownArrow()
        });

    }, [token, drawDownArrow, seed])
	
	const reloadFortis = (e) => {
        setLoaded(false)
        setFortisClientToken('')
        setSeed(Math.random())
	}

    return (
        <div className="main">
            <div className={tooBig ? 'down-arrow visible' : 'down-arrow'}>
                <span>Scroll down for more content.</span>
                <img src={downArrow} alt="down arrow" />
            </div>

            <Messages {...message} />

            {token && <div className={loaded && validToken ? 'wrapper loaded' : 'wrapper'}>
                <div className="co-header">
                    {(loaded && (company.current || contact.current)) && <div className="company-name">
                        {company.current && <h1>{company.current}</h1>}
                        {contact.current && <h3>Hi, {contact.current}!</h3>}
                    </div>}

                    {(loaded && logo.current) && <img className="company-logo" src={logo.current} alt="" />}
                </div>

                <div className="row">
                    <div className="invoice">
                        {(loaded && totalsData) && <Totals {...totalsData} />}
                    </div>

                    <div className="payment">

                        {!loaded && <div className="loading"></div>}

                        { fortisClientToken &&<FortisElements
                            token={token}
                            drawDownArrow={drawDownArrow}
                            setLoaded={setLoaded}
                            setMessage={setMessage}
                            setRetry={setRetry}
                            fortisClientToken={fortisClientToken}
                        /> }

                        {retry && <div className="tmp"><button type="button" onClick={reloadFortis}>Retry</button></div>}

                        {loaded && !message && <div className="rw-logo-cont">
                            <img className="rw-logo" src={rwLogo} alt="" />
                        </div>}
                    </div>
                </div>
            </div>}

            {!token && <div className="no-token"><h1>Quikpay</h1></div>}
        </div>
    );
}
